export default {
  defaultHeader: "HRMS App",
  "username.text": "Username",
  "companyMobile.text": "Company Mobile Number",
  "firstName.text": "First Name",
  "lastName.text": "Last Name",
  "city.text": "City",
  "state.text": "State",
  "password.text": "Password",
  "confirmPassword.text": "Confirm Password",

  // Button Text
  "button.text.save": "Save",
  "button.text.address": "Address",
  "button.text.delete": "DELETE",
  "button.text.upload": "UPLOAD",
  "button.text.cancel": "Cancel",
  "button.text.createPayroll": "Create New Payroll",
  "button.text.register": "Register",
  "button.text.login": "Login",
  "button.text.logging": "Logging...",

  // Login Form Text
  "login.title.portal-sign-in": "Portal Sign in",

  // My Profile
  "title.myProfile.text": "My Profile",
  "button.text.changePassword": "Change Password",
  "message.changePassword.sendOtp.success": "OTP Sent on your Email",
  "button.text.forgotPassword": "Forgot Password",
  "button.text.updatePassword": "Update Password",
  "button.text.passwordChangedSuccess.success": "Password Changed Successfully",

  // Company Registration form
  "heading.company.userDetail": "User Detail",
  "heading.company.registration": "Company Registration",
  "heading.company.verifyOtp": "Verify OTP",
  "form.otp.verifyOtp": "Verify OTP",
  "form.otp.text": "OTP",
  "form.registerCompany.otpSent": "We have sent 6 digit code to you company email",
  "form.registerCompany": "Company",
  "form.registerCompany.tittle": "Register your company here",
  "form.company.name": "Company Name",
  "form.registration.number": "Registration Number",
  "form.company.mobile": "Company Mobile",
  "form.company.email": "Company Email",
  "form.industry.type": "Industry Type",
  "form.employee.count.range": "Employee Count Range",
  "form.date.of.establishment": "Date Of Establishment",
  "form.address.line1": "Address Line1",
  "form.address.line2": "Address Line2",
  "form.city": "City",
  "form.state": "State",
  "form.country": "Country",
  "form.already.account": "Already have an account",
  "form.login.here": "Login here",
  "form.login.now": "Login Now",
  "form.register.btn": "Register",
  "form.login.here.account": "Please Login Here",
  "company.registration.success.message": "Thank you for registering your company with us.",
  "plan.buy.error.message": "Your current plan resource limit exceeds. Please buy higher plan.",

  // Forget Password
  "button.text.forgotPassword": "Send OTP",
  "button.text.updatePassword": "Update Password",
  "forget.password.oldPassword": "Old Password",
  "forget.password.newPassword": "New Password",
  "forget.password.confirmPassword": "Confirm Password",
  "forget.password.userName": "User Name",
  "forget.password.otp": "OTP",
  "send.otp.message": "Update Password",

  // Subscription Plan
  "form.text.yearly.plan.title": "Yearly plan",
  "form.text.halfYearly.plan.title": "Half Yearly plan",
  "form.text.quarterly.plan.title": "Quarterly plan",
  "form.text.Monthly.plan.title": "Monthly plan",
  "form.text.plan.detail": "Plan Details",
  "orders.currentPlan": "Plan Subscribed",
  "orders.myOrder": "My Order",
  "plan.buyNow": "Buy Now",
  "plan.upgradeNow": "Upgrade Now",
  "plan.buyThisNow": "Buy This Plan Again",
  "plan.renewPlan": "Renew Plan",
  "plan.trialNow": "Start Free Trial",
  "trial.message": "30 Days Free Trial!",

  // my order detail
  "order.orderDate": "Order Date",
  "order.orderNumber": "Order Number",
  "order.invoiceNumber": "Invoice",
  "order.tax": "Tax",
  "order.discount": "Discount",
  "order.netAmount": "Total Amount",
  "order.orderTitle": "Order Detail",
  "order.orderStatus": "Order Status",
  // current plan card
  "myPlan.title": "Current Plan",
  "myPlan.planDuration": "Plan Duration",
  "myPlan.planType": "Plan Type",
  "myPlan.maxEmployee": "Max Employee",
  "myPlan.maxAsset": "Max Asset",
  "myPlan.maxProject": "Max Project",
  "myPlan.maxDocument": "Max Document",
  "plan.startNow": "Start Free Trial",

  //navbar text
  "navbar.text.home": "Home",
  "navbar.text.feature": "Feature",
  "navbar.text.features": "Features",
  "navbar.text.start.free.trial": "Free Trial",
  "navbar.text.pricing": "Pricing",
  "navbar.text.faq": "FAQ",
  "navbar.text.aboutUs": "About Us",
  "navbar.text.login": "Login",
  "navbar.text.openPortalApp": "Portal",
  "navbar.text.myProfile": "My Profile",
  "navbar.text.currentPlan": "Current Plan",
  "navbar.text.myOrders": "My Orders",
  "navbar.text.changePassword": "Change Password",
  "navbar.text.logout": "Logout",

  //Invalid OTP error message
  "invalid.expired.otp": "Invalid OTP",
};
