import React from "react";
import { Helmet } from "react-helmet-async";
import { getAccessToken } from "../auth/HandleCookies";
import Features from "../features/Features";
import { PricingPlan } from "../pricing-plan/PricingPlan";
import SubscriptionPlan from "../subscription/currentSubscription";
import { About } from "./About";
import CookiesComponent from "./CookiesComponent";
//import Home from "./Home";
import ScheduleDemo from "./ScheduleDemo";
import YoutubeVideo from "../videos/YoutubeVideo";
import FAQ from "../faq/FAQ";

export default function LandingPage() {
  let accessToken = getAccessToken();

  return (
    <>
      <Helmet>
        <title>HRMS | Human Resource Management | Employee Management | Payroll Management | Leave & attendance | Asset Management</title>
        <meta
          name="description"
          content="Simplify your HR management with HRMSHOST, a comprehensive cloud-based HRMS software. Manage employees and streamline payroll, leave and attendence, and more."
        />
        <link
          rel="canonical"
          href="https://hrmshost.com/"
        />
        <link
          rel="canonical"
          href="https://hrmshost.com/#homeSection"
        />
        <link
          rel="canonical"
          href="https://hrmshost.com/#featuresSection"
        />
        <link
          rel="canonical"
          href="https://hrmshost.com/#pricingPlanSection"
        />
        <link
          rel="canonical"
          href="https://hrmshost.com/#FAQ"
        />
        <link
          rel="canonical"
          href="https://hrmshost.com/#aboutWraper"
        />
      </Helmet>
      <div className="contentSection">
        {accessToken && (
          <>
            <SubscriptionPlan />
            <PricingPlan />
            <Features />
            <FAQ />
            <YoutubeVideo />
          </>
        )}
        {!accessToken && (
          <>
            {/*<Home />*/}
            <About />
            <Features />
            <PricingPlan />
            <FAQ />
            <YoutubeVideo />
            <ScheduleDemo />
          </>
        )}
        <CookiesComponent />
      </div>
    </>
  );
}
