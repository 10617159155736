import React from "react";

const FAQ = () => {
  const objectsForFAQ = [
    {
      id: "flush-headingOne",
      data_bs_target: "#flush-collapseOne",
      aria_controls: "flush-collapseOne",
      questions: "Why should our organization use your HRMS System ?",
      answer: `Our Application is designed for Employee management, Payroll management, Asset management, Project management, and Leave management for all Business Owners and HR Personnel. 
        \n We have designed the screens to be straightforward and user-friendly, guiding you through each step of the all processes. It means all above services available at one platform so you can manage your business very easily with help of HRMS-HOST.`,
    },
    {
      id: "flush-headingTwo",
      data_bs_target: "#flush-collapseTwo",
      aria_controls: "flush-collapseTwo",
      questions: "How can I start using your HRMS System ?",
      answer: "You can simply start with free trial and later buy subscription as per your requirements.",
    },
    {
      id: "flush-headingThree",
      data_bs_target: "#flush-collapseThree",
      aria_controls: "flush-collapseThree",
      questions: "How to create payroll by using HRMS-HOST System ?",
      answer: "HRMS-HOST provide you a payroll management system integrated with leave and attendance management, so you can create and manage payroll very easily.",
    },
    {
      id: "flush-headingFour",
      data_bs_target: "#flush-collapseFour",
      aria_controls: "flush-collapseFour",
      questions: "What size of Organization can use HRMS-HOST ?",
      answer: `Organizations having up to two hundred employees can use the HRMS system directly by subscribing online package.
         \n Organizations having more employees can contact us directly to get customized subscription.`,
    },
    {
      id: "flush-headingFive",
      data_bs_target: "#flush-collapseFive",
      aria_controls: "flush-collapseFive",
      questions: "What features are available for an employee in employee portal ?",
      answer: "Employees can download their pay-slips, apply leaves, upload documents and view dashboard, Profile, Assigned Assets, Assigned Project, Insurance Details, Policies etc.",
    },
    {
      id: "flush-headingSix",
      data_bs_target: "#flush-collapseSix",
      aria_controls: "flush-collapseSix",
      questions: "What features are available for an Admin in HRMS Portal ?",
      answer: "A HRMS Admin can manage all the activity starting from adding a new employee and managing CTC, payroll, project and asset, leaves etc.",
    },
    {
      id: "flush-headingSeven",
      data_bs_target: "#flush-collapseSeven",
      aria_controls: "flush-collapseSeven",
      questions: "How many login types and applications are available under your HRMS System ?",
      answer: `We have two separate application login for HR Admin and Employee. 
      \n HR Admin can login to our HRMS Host website <a href='https://hrmshost.com'>https://hrmshost.com</a> and see orders and current subscription details. 
      \n HR Admin can directly move to Portal Application from website.
      \n HR Admin can also login separately to Portal Application <a href='https://portal.hrmshost.com'>https://portal.hrmshost.com</a> 
      \n Employees have separate application login at <a href='https://employee.hrmshost.com'>https://employee.hrmshost.com</a>`,
    },
    {
      id: "flush-headingEight",
      data_bs_target: "#flush-collapseEight",
      aria_controls: "flush-collapseEight",
      questions: "Does HRMS System support PWA and how to install as PWA App?",
      answer: `Yes, HRMS System support PWA,There are Following steps for install PWA. 
      \n <b>Desktop </b>:-
      \n Open Chrome.
      \n Go to a website that supports PWAs.
      \n At the top right of the address bar, click Install.
      \n Follow the on-screen instructions to install the PWA.
      \n Once the PWA is installed, you can launch it from your desktop or start menu.
      \n <b>Mobile </b>:-
      \n Open Chrome.
      \n Go to a website that supports PWAs.
      \n At the top right of the address bar, tap More (three dots).
      \n Tap Add to Home screen.
      \n Tap Add.
      \n Once the PWA is installed, you can launch it from your home screen.`,
    },
  ];

  return (
    <>
      <div
        className="heading section-scroll"
        id="FAQ">
        <span>Frequently Ask Questions</span>
      </div>
      <div className="card border-3 shadow-lg container-body mt-2 mb-5">
        <div className="card-body">
          <div
            className="accordion accordion-flush"
            id="accordionFlushExample">
            {objectsForFAQ.map((item) => {
              return (
                <div
                  key={"FAQ"}
                  className="accordion-item">
                  <h2
                    className="accordion-header"
                    id={item.id}>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={item.data_bs_target}
                      aria-expanded="false"
                      aria-controls={item.aria_controls}>
                      <h5 className="text-fontSize">{item.questions}</h5>
                    </button>
                  </h2>
                  <div
                    id={item.aria_controls}
                    className="accordion-collapse collapse"
                    aria-controls={item.aria_controls}
                    data-bs-parent="#accordionFlushExample">
                    <div className="accordion-body ">
                      {
                        <div
                          className="text-fontSize"
                          dangerouslySetInnerHTML={{ __html: item.answer }}
                        />
                      }
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;
